import { PlatformCRMWeb } from 'src/services/GrpcService';
import { Type } from 'class-transformer';
import { CalculatePriceDto } from '../ProspectStore';
import { AddressDto, PersonDto } from '../CustomerStore';

export class OrderDto implements PlatformCRMWeb.IOrder {
  id!: string;
  orderNumber!: number;
  status?: string;
  @Type(() => PersonDto)
  person!: PersonDto;
  prospectUID!: string;
  executorId!: number;
  startDate!: string;
  @Type(() => AddressDto)
  address!: AddressDto;
  regionId!: number;
  @Type(() => CalculatePriceDto)
  price!: CalculatePriceDto;
  promocode!: string;
  serviceType!: string;
  paymentType!: string;
  paymentTypeMeta!: string;
  paymentStatus!: string;
  cardNumber!: string;
  qleanOrderId!: string;
  commentForExecutor!: string;
  commentForSupport!: string;
  score!: number;
  cleanerId!: string;
  cancelReason?: string;
  totalPrice!: number;
  totalDuration!: number;
  isOvertime!: boolean;
  commentFromCustomer!: string;
  discount!: number;
  @Type(() => AddressDto)
  keysDelivery?: AddressDto;
  @Type(() => AddressDto)
  keysPickup?: AddressDto;
  bonusesUsed?: number;
  prospectId!: string;
  numberOfPayments?: number;
}

export class ShortOrderDto implements PlatformCRMWeb.IShortOrder {
  id?: string;
  orderNumber!: number;
  status!: string;
  @Type(() => AddressDto)
  address!: AddressDto;
  regionId!: number;
  startDate!: string;
  serviceType!: string;
  qleanOrderId!: string;
  promocode!: string;
  totalPrice!: number;
  @Type(() => CalculatePriceDto)
  price!: CalculatePriceDto;

  public isService(serviceSlug: string): boolean {
    return this.serviceType === serviceSlug;
  }
}

export class ExecutorDto implements PlatformCRMWeb.IExecutor {
  id!: string;
  rate!: number;
  name!: string;
  phone!: string;
  role!: number;
  ssoId!: string;
  qleanId!: number;
}

export class GetOrderByIdResponseDto implements PlatformCRMWeb.IGetOrderByIdResponse {
  @Type(() => OrderDto)
  order!: OrderDto;
  @Type(() => ExecutorDto)
  executors!: ExecutorDto[];
}

export class GetExecutorsListResponseDto implements PlatformCRMWeb.IGetExecutorsListResponse {
  @Type(() => ExecutorDto)
  executors!: ExecutorDto[];
}

export class SearchOrdersResponseDto implements PlatformCRMWeb.ISearchOrdersResponse {
  @Type(() => ShortOrderDto)
  data!: ShortOrderDto[];
  page!: number;
  pageCount!: number;
  limit!: number;
  count!: number;
  total!: number;
}

export class RejectionReasonDto implements PlatformCRMWeb.IRejectionReason {
  id!: string;
  title!: string;
  slug!: string;
}

export class RejectionGroupDto implements PlatformCRMWeb.RejectionGroup {
  id!: string;
  type!: PlatformCRMWeb.REJECTION_GROUP_TYPE;
  title!: string;
  parentGroupId!: string;
  reasons!: RejectionReasonDto[];
  groups?: RejectionGroupDto[];
}
